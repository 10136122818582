<template>
  <v-dialog v-model="dialogDelete"
            :max-width="maxWidth"
            persistent
            @keydown.esc="cancel">
    <sb-card :title="title">
      <v-card-text class="pa-4 text-h3">
        {{ textMessage }}
      </v-card-text>

      <template slot="actions"
                class="pt-0">
        <v-spacer />
        <v-btn color="secondary"
               outlined
               @click="dialogDelete=false">
          Cancel
        </v-btn>
        <v-btn color="secondary"
               outlined
               @click="confirm">
          OK
        </v-btn>
        <v-spacer />
        <v-spacer />
      </template>
    </sb-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '500',
    },
    title: {
      type: String,
      default: undefined,
    },
    textMessage: {
      type: String,
      default: undefined,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogDelete: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
