var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.dataTableItems,"loading":_vm.loading,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',{attrs:{"scope":"row"}},[_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,staticClass:"text-left sb-bg",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(header.text)+" ")])}),(_vm.showErrors)?_c('th',{staticClass:"text-left sb-bg",attrs:{"scope":"col"}},[_vm._v(" Validation Error ")]):_vm._e(),(_vm.showActions)?_c('th',{staticClass:"text-left sb-bg",attrs:{"scope":"col"}},[_vm._v(" Actions ")]):_vm._e()],2)])]},proxy:true},((!_vm.loading && _vm.dataTableItems.length > 0))?{key:"body",fn:function(){return [_c('tbody',_vm._l((_vm.dataTableItems),function(item,index){return _c('tr',{key:index,attrs:{"scope":"row"}},[_vm._l((_vm.headers),function(header,headerIndex){return _c('td',{key:headerIndex,class:{'sb-nowrap': header.dataType === 'Date' || header.dataType === 'Currency'
                             || header.isFlaged || header.nowrap, 'negative-number': _vm.setNegativeNumber(header, item)
                           ,'text-align-right': header.align === 'right'}},[(header.isFlaged && item.isDuplicate)?_c('v-icon',{attrs:{"left":"","color":_vm.updateFlagColor(item)}},[_vm._v(" mdi-flag-variant ")]):_vm._e(),_c('sb-input',{attrs:{"label":_vm.getItem(item, header),"show-red-text":_vm.setNegativeNumber(header, item)}})],1)}),(_vm.showErrors)?_c('td',{staticClass:"text-align-right sb-nowrap negative-number"},[(item.validationErrors.length > 0)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.showErrorDialog(item.validationErrors)}}},[_vm._v(" "+_vm._s(_vm.truncate(item.validationErrors))+" ")]):_vm._e()],1):_vm._e(),(_vm.showActions)?_c('td',{staticClass:"text-align-right"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                           var on = ref.on;
                           var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.actionItems),function(action,actionIndex){return _c('v-list-item',{key:actionIndex,attrs:{"disabled":!item.isDuplicate ? action.disabled: false},on:{"click":function($event){return _vm.selectedAction(action, item, index)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                           var on = ref.on;
                           var attrs = ref.attrs;
return [_c('v-list-item-icon',[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(action.value))])])],1)}),1)],1)],1):_vm._e()],2)}),0)]},proxy:true}:null,{key:"no-data",fn:function(){return [_c('sb-empty-message',{attrs:{"message":_vm.emptyMessage,"sub-message":_vm.emptySubMessage}})]},proxy:true},{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_c('sb-loader',{staticClass:"mt-5"})],1)]},proxy:true}],null,true)})],1)],1),(!_vm.hidePagination && _vm.dataTableItems.length > 0)?_c('sb-pagination',{attrs:{"items-per-page":_vm.defaultPagination ? _vm.pagination.numberOfElements :_vm.itemsPerPage,"total-pages":_vm.defaultPagination ? _vm.pagination.totalPages : _vm.totalPages,"page":_vm.defaultPagination ? _vm.pagination.number:_vm.currentPage},on:{"update-items-per-page":_vm.updateItemsPerPage,"update-page":_vm.updatePage}}):_vm._e(),_c('sb-confirm-dialog',{attrs:{"show-dialog":_vm.dialogDelete,"text-message":_vm.confirmDialogMessage},on:{"close":function($event){_vm.dialogDelete=false},"confirm":_vm.deleteItemConfirm}}),_c('sb-message-dialog',{attrs:{"show-dialog":_vm.errorDialog,"title":'Validation Errors',"text-message":_vm.errorMessage,"errors":_vm.itemErrors,"show-erros-as-list":_vm.showErrosAsList},on:{"close":function($event){_vm.errorDialog=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }