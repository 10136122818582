<template>
  <v-dialog v-model="dialog"
            :max-width="maxWidth"
            persistent
            @keydown.esc="cancel">
    <sb-card :title="title">
      <div v-if="showErrosAsList">
        <v-list-item v-for="(error, actionIndex) in errors"
                     :key="actionIndex">
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              {{ error.toLowerCase() | startCase }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-card-text v-else
                   class="pa-4 text-h3">
        {{ textMessage }}
      </v-card-text>
      <template slot="actions"
                class="pt-0">
        <v-spacer />
        <v-btn color="secondary"
               outlined
               @click="dialog=false">
          Cancel
        </v-btn>
      </template>
    </sb-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '620',
    },
    title: {
      type: String,
      default: undefined,
    },
    textMessage: {
      type: String,
      default: undefined,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    showErrosAsList: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
};
</script>
