<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table :search="search"
                      :headers="headers"
                      :items="dataTableItems"
                      :loading="loading"
                      hide-default-footer
                      hide-default-header>
          <template #header>
            <thead>
              <tr scope="row">
                <th v-for="(header, index) in headers"
                    :key="index"
                    class="text-left sb-bg"
                    scope="col">
                  {{ header.text }}
                </th>
                <th v-if="showErrors"
                    class="text-left sb-bg"
                    scope="col">
                  Validation Error
                </th>
                <th v-if="showActions"
                    class="text-left sb-bg"
                    scope="col">
                  Actions
                </th>
              </tr>
            </thead>
          </template>
          <template v-if="(!loading && dataTableItems.length > 0)"
                    #body>
            <tbody>
              <tr v-for="(item, index) in dataTableItems"
                  :key="index"
                  scope="row">
                <td v-for="(header, headerIndex) in headers"
                    :key="headerIndex"
                    :class="{'sb-nowrap': header.dataType === 'Date' || header.dataType === 'Currency'
                               || header.isFlaged || header.nowrap, 'negative-number': setNegativeNumber(header, item)
                             ,'text-align-right': header.align === 'right'}">
                  <v-icon v-if="header.isFlaged && item.isDuplicate"
                          left
                          :color="updateFlagColor(item)">
                    mdi-flag-variant
                  </v-icon>

                  <sb-input :label="getItem(item, header)"
                            :show-red-text="setNegativeNumber(header, item)" />
                </td>
                <td v-if="showErrors"
                    class="text-align-right sb-nowrap negative-number">
                  <v-chip v-if="item.validationErrors.length > 0"
                          class="ma-2"
                          color="red"
                          outlined
                          @click="showErrorDialog(item.validationErrors)">
                    {{ truncate(item.validationErrors) }}
                  </v-chip>
                </td>
                <td v-if="showActions"
                    class="text-align-right">
                  <v-menu offset-y
                          left>
                    <template #activator="{on, attrs}">
                      <v-icon small
                              v-bind="attrs"
                              v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item v-for="(action, actionIndex) in actionItems"
                                   :key="actionIndex"
                                   :disabled="!item.isDuplicate ? action.disabled: false"
                                   @click="selectedAction(action, item, index)">
                        <v-tooltip bottom>
                          <template #activator="{on, attrs}">
                            <v-list-item-icon>
                              <v-icon v-bind="attrs"
                                      v-on="on">
                                {{ action.icon }}
                              </v-icon>
                            </v-list-item-icon>
                          </template>
                          <span> {{ action.value }}</span>
                        </v-tooltip>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
          <template #no-data>
            <sb-empty-message :message="emptyMessage"
                              :sub-message="emptySubMessage" />
          </template>
          <template #loading>
            <v-row justify="center">
              <sb-loader class="mt-5" />
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <sb-pagination v-if="!hidePagination && dataTableItems.length > 0"
                   :items-per-page="defaultPagination ? pagination.numberOfElements :itemsPerPage"
                   :total-pages="defaultPagination ? pagination.totalPages : totalPages"
                   :page="defaultPagination ? pagination.number:currentPage"
                   @update-items-per-page="updateItemsPerPage"
                   @update-page="updatePage" />
    <sb-confirm-dialog :show-dialog="dialogDelete"
                       :text-message="confirmDialogMessage"
                       @close="dialogDelete=false"
                       @confirm="deleteItemConfirm" />
    <sb-message-dialog :show-dialog="errorDialog"
                       :title="'Validation Errors'"
                       :text-message="errorMessage"
                       :errors="itemErrors"
                       :show-erros-as-list="showErrosAsList"
                       @close="errorDialog=false" />
  </div>
</template>
<script>
import SbPagination from '@/components/core/SbPagination';
import paginationBase from '@/mixins/paginationBase';
import SbConfirmDialog from '@/components/core/SbConfirmDialog';
import SbMessageDialog from '@/components/core/SbMessageDialog';
import { TABLE_ACTIONS } from '@/config/constants';
import get from 'lodash/get';
import Pagination from '@/models/Pagination';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';

export default {
  components: {
    SbPagination,
    SbConfirmDialog,
    SbMessageDialog,
  },
  mixins: [paginationBase],
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    emptyMessage: {
      type: String,
      default: undefined,
    },
    emptySubMessage: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    defaultPagination: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: undefined,
    },
    showErrosAsList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: this.page,
      editedIndex: -1,
      actionItems: TABLE_ACTIONS,
      dialogDelete: false,
      selectedReults: {},
      confirmDialogMessage: ' Are you sure you want to delete this item?',
      errorMessage: '',
      errorDialog: false,
      dataTableItems: [],
      pagination: new Pagination(),
      itemErrors: [],
    };
  },
  computed: {
    isDefaultPagination() {
      return !this.hidePagination && this.defaultPagination;
    },
  },
  watch: {
    items: {
      handler(value) {
        if (value.length > 0 && this.isDefaultPagination) {
          this.setJournalItems(value, this.currentPage);
        } else {
          this.dataTableItems = this.items;
        }
      },
      immediate: true,
    },
    currentItemsPerPage(value) {
      this.numberOfElements = value;
      if (this.isDefaultPagination) {
        this.setJournalItems(this.items, this.currentPage);
      } else {
        this.$emit('update:itemsPerPage', value);
      }
    },
    currentPage(value) {
      if (this.isDefaultPagination) {
        this.setJournalItems(this.items, this.currentPage);
      } else {
        this.$emit('update:page', value);
      }
    },
    search: {
      handler() {
        // TO DO: implement search
      },
    },
  },
  methods: {
    selectedAction(selectedAction, selectedItem, index) {
      if (!this.hidePagination) {
        if (this.isDefaultPagination) {
          this.editedIndex = (this.pagination.number - 1) * this.pagination.numberOfElements + index;
        } else {
          this.editedIndex = (this.page - 1) * this.itemsPerPage + index;
        }
      } else {
        this.editedIndex = this.items.indexOf(selectedItem);
      }
      this.selectedReults = {
        selectedAction: selectedAction.value,
        selectedIndex: this.editedIndex,
        selectedItem,
      };
      if (selectedAction.value === 'Remove') {
        this.dialogDelete = true;
        return;
      }
      this.$emit('selected-item', this.selectedReults);
    },

    deleteItemConfirm() {
      this.dialogDelete = false;
      this.$emit('selected-item', this.selectedReults);
    },

    updateFlagColor(item) {
      if (item.isDuplicate && item.isAcknowledged) {
        return '#008000';
      }
      return '#ff5a00';
    },
    truncate(array) {
      let error = '';
      if (array.length > 1) {
        error = _capitalize(_startCase(array.slice().sort().join(', ').toLowerCase()));
        const size = 20;
        return error.length > size ? `${error.slice(0, size - 1)}…` : error;
      }
      error = array.join();
      return _capitalize(_startCase(error.toLowerCase()));
    },

    getItem(item, header) {
      return get(item, header.value);
    },

    setJournalItems(items, currentPage) {
      let page = currentPage;
      const paging = this.tableDefaultPagination(items, this.numberOfElements, page - 1);
      let dataItems = paging.items;
      if (paging.items.length === 0 && items.length > 0) {
        // reset data table
        page = 0;
        dataItems = items;
      }
      this.pagination.number = page;
      this.pagination.totalPages = paging.totalPages;
      this.pagination.numberOfElements = this.numberOfElements;
      this.dataTableItems = dataItems;
    },

    tableDefaultPagination(items, pageSize, pageNumber) {
      /* eslint-disable */
      const totalPages = items.reduce((array, item, index) => {
        const chunkIndex = Math.floor(index / pageSize);
        array[chunkIndex] = [].concat(array[chunkIndex] || [], item);
        return array;
      }, []).length;

      const results = items.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize);
      return { totalPages, items: results };
    },
    setNegativeNumber(header, item) {
      return header.dataType === 'Currency' && get(item, header.value) < 0;
    },

    showErrorDialog(errors) {
      this.errorDialog = true;
      if (this.showErrosAsList) {
        this.itemErrors = errors;
        return;
      }
      if (errors.length > 1) {
        this.errorMessage = errors.slice().sort().join(', ');
      } else {
        this.errorMessage = errors.join();
      }
    },
  },
};
</script>
<style scoped>
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.8rem !important;
  }
  .text-align-right {
    text-align: right;
  }
  .v-list-item {
    padding: 0px;
  }
</style>
